const {MD5} = require('object-hash')

export function getClientFingerprint() {
    // let fingerprint;
    // try {
    //     // Get the client's fingerprint id
    //     fingerprint = new ClientJS().getFingerprint();
    //     // Print the 32bit hash id to the console
    // } catch (error) {
    //     console.error("Fingerprint clientjs failed.");
    //     // expected output: ReferenceError: nonExistentFunction is not defined
    //     // Note - error messages will vary depending on browser
    // }
    //
    // if (!fingerprint) {
    //     console.log("Falling back to internal solution");
    //     fingerprint = getFingerprintInternal();
    // }
    return getFingerprintInternal();
}

function getFingerprintInternal() {
    // device-type
    // screen-size
    // pixel ratio https://github.com/jorgegilramos/screen-dpi-detector/blob/master/js/screen.dpi.detector.js
    const clientJS = new ClientJS();
    let height = window.screen.height
    let width = window.screen.width
    let avail_height = window.screen.availHeight
    let avail_width = window.screen.availWidth
    let pixelRatio = window.devicePixelRatio || 1
    let language = navigator.language;
    let languages = navigator.languages;
    let os = clientJS.getOS();
    let os_v = clientJS.getOSVersion();
    let device = clientJS.getDevice();
    let device_type = clientJS.getDeviceType();
    let device_vendor = clientJS.getDeviceVendor();
    let is_android = clientJS.isMobileAndroid();
    let is_ios = clientJS.isMobileIOS()
    let is_iphone = clientJS.isIphone();
    let is_ipad = clientJS.isIpad();
    let tz = clientJS.getTimeZone();
    let cpu = clientJS.getCPU();
    // let ua = navigator.userAgent;

    const fingerprint = {
        'width': width,
        'height': height,
        'avail_width': avail_width,
        'avail_height': avail_height,
        'pixelRation': pixelRatio,
        'language': language,
        'languages': languages,
        'os': os,
        'os_v': os_v,
        'device': device,
        'device_type': device_type,
        'device_vendor': device_vendor,
        'is_android': is_android,
        'is_ios': is_ios,
        'is_ipad': is_ipad,
        'is_iphone': is_iphone,
        'tz': tz,
        'cpu': cpu,
    }
    const hash = MD5(fingerprint)
    return {
        'raw_fingerprint': new URLSearchParams(fingerprint).toString(),
        'hash': hash
    }
}