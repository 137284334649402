import AppStoreImage from '../images/App_Store_Badge_US.svg'
import PlayStoreImage from '../images/google-play-badge.png'

const { v4: uuidv4 } = require('uuid')
const { getClientFingerprint } = require('./mobile_hash');

window.onload = function()
{
    let mobileHash = getClientFingerprint()

    let uuid = uuidv4()
    const url = '/switch/'+uuid+'?client_info='+mobileHash.hash+'&raw_client_info='+mobileHash.raw_fingerprint;
    Rails.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        contentType:'application/json',
        secure: true,
        success: function (data) {
            document.getElementById("product-link").href = data.external_url;
            document.getElementById("product-link").onclick = function () {
                redirect(data.external_url);
                return false;
            };

            // auto redirect based on redirect mode
            auto_redirect(data);
        }
    })
};

function auto_redirect(data) {
    let redirectMode = data.rm;
    switch (redirectMode) {
        case 0:
            // no redirect
            break;
        case 1:
            // immediate redirect
            redirect(data.external_url);
            break;
        case 2:
            // redirect after 3 secs
            setTimeout(function() { redirect(data.external_url); }, 3000);
            break;
    }
}

function redirect(url) {
    window.location.replace(url);
}

function get_store_btn(store) {
    let link;
    let store_img;
    if (store) {
        link = document.createElement("a");
        link.href = store.external_url;
    }
    if (link && store.type === "apple") {
        store_img = document.createElement("img");
        store_img.src = AppStoreImage
        store_img.height = 40
    } else if (link && store.type === "gpl") {
        store_img = document.createElement("img");
        store_img.src = PlayStoreImage
        store_img.height = 50
    } else if (link && store.type === "web") {
        link.text = "Game link"
    }
    if (link && store_img) {
        link.appendChild(store_img)
    }
    return link
}
